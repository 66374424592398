@import "../mixins/mixins";

@import "../utils/variables";

.header {
    height: 90px;
    padding-top: 32px;
    margin-bottom: -90px;
    position: relative;
    z-index: 20;
    &-grid {
        grid-template-columns: 300px 300px auto 220px 200px;
        grid-column-gap: 8px;
        align-items: center;
    }
    &-logotype{
        @include logo;
    }
    &-address {
        padding-left: 48px;
        position: relative;
        font-family: $regular;
        font-size: $fs-info;
        color: $white;
        &::after{
            content: '';
            position: absolute;
            background: url("/img/moscow-metro.svg") no-repeat center/contain;
            width: 32px;
            height: 24px;
            left: 0;
            top: 0;
        }
    }
    &-phone {
        color: $white;
        font-family: $bold;
        font-size: $fs-paragraph;
    }
    &-btn {
        padding: 18px 32px;
        font-size: $fs-label;
        text-align: center;
    }
    &-mobile-menu {
        display: none;
        height: 24px;
        width: 20px;
        background: url(/img/mobile-menu.svg) no-repeat center/contain;
        cursor: pointer;
        margin-left: 10px;
    }
}

@media screen and (max-width: 1366px) {
    .header{
        &-grid {
            grid-template-columns: 140px 300px auto 200px 180px;
            grid-column-gap: 16px
        }
        &-logotype {
            margin-left: 0;
        }
    }
}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 992px) {
    .header {
        background: $main-text-color;
        color: $white;
        margin: 0;
        height: 50px;
        padding: 8px 0 0;
        position: fixed;
        width: 100%;
        &-grid {
            grid-column-gap: 4px;
            grid-template-columns: 1fr 1fr auto 1fr;
            grid-auto-flow: column;
        }
        &-logotype {
            margin: 0 auto;
        }
        &-address,
        &-btn{
            display: none;
        }
        &-logotype {
            height: 32px;
        }
        &-phone {
            text-indent: -9999px;
            width: 20px;
            height: 20px;
            overflow: hidden;
            background: url(/img/phone.svg) no-repeat center/contain;
            justify-self: right;
            margin-right: 18px;
        }
        &-mobile-menu {
            display: block;
        }
    }
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 575px) {

}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 320px) {

}
