@import "../utils/variables";

@mixin blur($property) {
    backdrop-filter: blur($property);
    -webkit-backdrop-filter: blur($property);
}

@mixin transform($property) {
    -webkit-transform: scale($property);
    -ms-transform: scale($property);
    transform: scale($property);
}

@mixin brightness($property) {
    filter: brightness($property);
    -webkit-filter: brightness($property);
}
@mixin gradient-linear ($direction, $color1, $color2) {
    background: -webkit-linear-gradient($direction $color1, $color2);
    background: -moz-linear-gradient($direction $color1, $color2);
    background: -o-linear-gradient($direction $color1, $color2);
    background: linear-gradient(to $direction, $color1, $color2);
}

@mixin card {
    background: $white;
    border-radius: $border-radius-cards;
    padding: $padding-cards-full;
    box-shadow: $box-shadow-cards;
    overflow: hidden;
}

@mixin underlined {
    padding-bottom: 6px;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 1px;
        background: #707070;
        transition: width .25s ease;
    }
    &:hover::after {
        width: 0;
    }
}

@mixin after-bg {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    transition: all .25s ease;
}

@mixin top-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
}

@mixin full-sized-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    transition: all .25s ease;
    z-index: 999;
}

@mixin logo {
    background: url('/img/logo.svg') no-repeat center/contain;
    text-indent: -9999px;
    width: 134px;
    height: 55px;
    transition: all .25s ease;
    margin-left: 32px;
    &:hover {
        @include transform(.95);
    }
}

@mixin grid-reset {
    grid-template-rows: auto;
    grid-template-columns: auto;
}
