// Mobile first
$mediaWidth320: 320px;
$mediaWidthM480: 480px;
$mediaWidth768: 768px;
$mediaWidth1024: 1024px;
$mediaWidth1366: 1366px;

//fonts
$fs-label: 14px;
$fs-info: 15px;
$fs-text: 16px;
$fs-paragraph: 18px;
$fs-subheader: 22px;
$fs-header: 28px;
$fs-title: 32px;
$fs-title-bigger: 36px;
$fs-biggest: 42px;

$lh-text: 20px;
$lh-paragraph: 26px;
$lh-biggest: 42px;

$regular: 'DIN Pro';
$medium: 'DIN Pro Medium';
$bold: 'DIN Pro Bold';

//colors
$black: #000;
$white: #fff;
$gray: #b9b9b9;
$accent-color: #CB2A2A;
$secondary-accent-color: #912525;
$dark-background: #3D3D3D;
$gray-background: #d9d9d9;
$light-background: #F0F0F0;
$main-text-color: #1E1E1E;
$second-text-color: #3D3D3D;
$muted-text-color: #B9B9B9;
$blurred-shadow: rgba(0,0,0,.33);
$muted-paragraph: rgba(61,61,61,.66);
$muted-white: rgba(255,255,255,.66);

//margins
$margin-small: 16px;
$margin-cards: 24px;
$margin-medium: 32px;
$margin-header: 48px;
$margin-section: 64px;
$margin-section-big: 128px;

//cards
$border-radius-cards: 8px 8px 8px 8px;
$box-shadow-cards: 0px 10px 20px rgba(0,0,0,.05);
$box-shadow-cards-hover: 0px 20px 30px rgba(0,0,0,.15);
$padding-cards-bottom: 30px;
$padding-cards-small: 32px;
$padding-cards-full: 48px;
$padding-cards-medium: 48px 32px;
$padding-big: 52px 48px;

//btns
$padding-btn: 18px 48px;
$border-radius-btn: 27px;
