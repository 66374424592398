@import "../../local_modules/normalize.css/normalize";

@import "../../local_modules/tiny-slider/dist/tiny-slider";

@import "../utils/fonts";

@import "../mixins/mixins";

@import "../utils/variables";

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    padding-left: 80px;
    font-family: $regular;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}


.grid {
    display: grid;
    display: -moz-grid;
}

.container {
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    z-index: 1;
}

.container-fluid {
    width: 100%;
    position: relative;
}

.accent-color {
    color: $accent-color;
}

.plain-text {
    color: $main-text-color;
}

.muted-text {
    color: $muted-text-color;
}

.text-center {
    text-align: center;
}

.btn {
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    text-align: center;
    border: none;
    padding: $padding-btn;
    color: $white;
    background: $accent-color;
    border-radius: $border-radius-btn;
    font-family: $bold;
    font-size: $fs-text;
    transition: background-color .25s ease;

    &:hover {
        background-color: $secondary-accent-color;
    }

    &[type="submit"] {
        width: 100%;
    }

}

.card {
    @include card;
}

.section-header {
    font-size: $fs-header;
    font-family: $bold;
    text-transform: uppercase;
    color: $main-text-color;
    margin-bottom: $margin-header;
}

.articles-header {
    font-family: $bold;
    font-size: $fs-subheader;
    margin-bottom: $margin-cards;
}

.subtitle {
    font-size: $fs-text;
    font-family: $bold;
    margin-top: $margin-small;
    margin-bottom: $margin-medium;
}

.paragraph-text {
    font-size: $fs-text;
    color: $main-text-color;
    line-height: $lh-text;
}


.form {
    &-label {
        position: absolute;
        top: 20px;
        left: 24px;
        display: inline-block;
        padding: 1px 8px;
        background: #fff;
        z-index: 11;
        color: $gray;
        font-family: $medium;
        font-size: $fs-label;
        visibility: hidden;
        opacity: 0;
        transition: all .25s ease;
    }

    &-input {
        border: 1px solid $light-background;
        height: 54px;
        display: block;
        padding: 12px 32px;
        width: 100%;
        border-radius: $border-radius-cards;
        transition: all .25s ease;
        outline: none;
        font-family: $medium;
        font-size: $fs-text;

        &:focus {
            outline: none;
            border-color: $gray;
        }

        &-input::placeholder {
            color: $gray;
        }

        &:focus ~ .form-label {
            top: 0;
            visibility: visible;
            opacity: 1;
        }
    }

    &-checkbox {
        margin-right: 8px;
    }

    &-checkbox-label {
        font-family: $regular;
        font-size: $fs-label;
    }

    &-textarea {
        border: 1px solid $light-background;
        display: block;
        padding: 12px;
        width: 100%;
        border-radius: $border-radius-cards;
        transition: all .25s ease;
        outline: none;
        font-family: $medium;
        font-size: $fs-text;
    }

    &-paragraph {
        font-size: $fs-paragraph;
        font-family: $regular;
        margin-bottom: $margin-medium;
    }

    &-wrap {
        margin-bottom: $margin-small;
        position: relative;
        padding-top: 8px;
    }

    &-agreement-link {
        color: $accent-color;
    }
}

// TNS
.tns-controls {
    position: relative;
    text-align: right;

    &:focus {
        outline: none;
    }
}

.tns-outer [aria-controls], .tns-outer [data-action] {
    text-indent: -9999px;
    border-radius: 50%;
    background: url('/img/chevron-right.svg') no-repeat center center $light-background;
    height: 52px;
    width: 52px;
    border: none;
    outline: none;
    position: relative;
    opacity: 0.5;
    transition: all .25s ease;

    &:hover {
        opacity: 1;
    }
}

.tns-outer [data-controls="prev"] {
    background: url('/img/chevron-left.svg') no-repeat center center $light-background;
    margin-right: 12px;
}

.tns-nav {
    text-align: center;
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    & [aria-controls] {
        opacity: 0.3;
        background: #d0d0d0;
        width: 16px;
        height: 16px;
        margin: 0 $margin-cards/2;
    }

    & [aria-controls].tns-nav-active {
        background: $accent-color;
        width: 24px;
        height: 24px;
        opacity: 1;
        margin: 0 8px;
    }
}


// Pannellum

.pnlm-ui .pnlm-panorama-info {
    bottom: 32px;
    left: 80px;
    border-radius: $border-radius-cards;
    font-size: $fs-info;
    font-family: $regular;
}

// BANNER

.main {
    position: relative;
    z-index: 1;

    & .tns-nav {
        position: absolute;
        top: calc(50% - 24px);
        width: 40px;
        height: auto;
        right: 48px;
        z-index: 50;
        flex-direction: column;
    }

    & [aria-controls] {
        margin: 8px 0;
    }

    & [aria-controls].tns-nav-active {
        margin: 8px 0;
    }
}

.banner {
    position: relative;
    z-index: 40;
    height: 100vh;

    &::after {
        @include after-bg;
        background: rgba(0, 0, 0, .33);
        z-index: 42;
    }

    & .container {
        z-index: 43;
    }

    &-slide {
        position: relative;
        overflow: hidden;
        height: 100vh;

        &-img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 41;
        }

        &-block {
            padding: $padding-cards-full;
            color: $white;
            margin-top: 256px;
            margin-left: 256px;
            position: relative;
            z-index: 44;
            max-width: 480px;
        }

        &-header {
            font-size: $fs-biggest;
            line-height: $fs-biggest;
            margin-bottom: $margin-cards;
            font-family: $bold;
            text-transform: uppercase;
        }

        &-text {
            color: $muted-white;
            font-size: $fs-subheader;
            line-height: $fs-subheader*1.4;
            font-family: $regular;
            margin-bottom: $margin-section;
        }
    }
}

// MEDIA

.media {
    margin-bottom: 96px;

    & .container {
        z-index: 3;
    }

    & .tns-outer {
        position: relative;
    }

    & .tns-controls {
        position: absolute;
        z-index: 80;
        top: calc(50% - 52px);
        left: 0;
        width: 100%;
        text-align: left;
        padding: 0 12px;
        height: 0;
        display: flex;
        justify-content: space-between;
    }

    &-grid {
        grid-template-columns: 6.5fr 5fr;
        grid-column-gap: 48px;
        margin-bottom: $margin-section;

    }

    &-carousel {
        margin: 32px 0 48px;

        &-img {
            border-radius: $border-radius-cards;
            height: 288px;
            object-fit: cover;
        }

        &-label {
            position: absolute;
            left: 0;
            width: calc(100% - 16px);
            bottom: -64px;
            padding: 12px 24px 12px 32px;
            color: $white;
            font-size: $fs-text;
            font-family: $medium;
            transition: all .3s ease;
            z-index: 15;
        }

        &-item {
            position: relative;
            border-radius: $border-radius-cards;
            overflow: hidden;
            transition: all .3s ease;

            &::before {
                @include after-bg;
                width: calc(100% - 16px);
                bottom: 4px;
                opacity: 0;
                visibility: hidden;
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, .66) 100%);
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, .66) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, .66) 100%);
                border-radius: $border-radius-cards;
                z-index: 10;
                transition: all .3s ease;
            }

            &:hover {
                margin-top: -10px;
            }

            &:hover::before {
                opacity: 1;
                visibility: visible;
            }

            &:hover .media-carousel-label {
                bottom: 24px;
            }

        }
    }

    &-paragraph {
        font-size: $fs-paragraph;
        font-family: $regular;
        color: $second-text-color;
    }

    &-text {
        padding-top: $margin-section;
    }

    &-card {
        max-width: 472px;
        padding: 0;
        margin-top: -48px;
        position: relative;
        z-index: 90;

        &-text {
            padding: $padding-cards-small $padding-cards-small $margin-section $padding-cards-small;
        }

        &-name {
            font-size: $fs-header;
            font-family: $bold;
            margin-bottom: $margin-small;
        }

        &-paragraph {
            font-size: $fs-text;
            line-height: $fs-text*1.4;
            color: $muted-paragraph;
        }

        &-video {
            position: relative;
        }

        &-clip {
            min-width: 375px;
            width: 100%;
            height: 265px;
        }
    }
}

section.address-mobile {
    display: none;
}

//  ABOUT
.about {
    padding: 80px 0 40px;
    background-color: $light-background;
    margin-bottom: $margin-section;

    & .tns-controls {
        @include top-nav;
    }

    & .tns-controls [aria-controls] {
        background: url('/img/chevron-right.svg') no-repeat center center $white;
    }

    & .tns-outer [data-controls="prev"] {
        background: url('/img/chevron-left.svg') no-repeat center center $white;
    }

    &-slider {
        padding-bottom: $margin-medium;
        padding-top: 10px;
        margin-bottom: $margin-section;
    }

    &-slide-card {
        padding: 0;
    }

    &-slide-img {
        border-radius: 8px 8px 0 0;
        height: 220px;
        width: 100%;
        object-fit: cover;
    }

    &-slide-info {
        padding: 24px 32px 48px;
    }

    &-slide-name {
        font-size: $fs-subheader;
        font-family: $bold;
        margin-bottom: $margin-small;
    }

    &-slide-text {
        font-size: $fs-text;
        line-height: $lh-text;
        color: $muted-paragraph;
    }
}

.tour {
    height: 640px;
    background: #e8e8e8;

    &-navigation {
        margin-bottom: $margin-section;
        margin-top: $margin-medium;
    }

    &-navigation-link {
        display: inline-block;
        font-family: $medium;
        color: $main-text-color;
        padding: 9px 22px;
        font-size: $fs-info;
        background-color: $gray-background;
        border-radius: 27px;
        margin-right: 8px;
        cursor: pointer;
        transition: background-color .25s ease;
    }

    &-navigation-link.active {
        background-color: $accent-color;
        color: $white;
    }

    &-navigation-link:hover {
        background-color: $muted-text-color;
    }

    &-navigation-link.active:hover {
        background-color: $accent-color;
    }

    &-form {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 472px;
    }

    &-subtitle {
        color: $accent-color;
        margin-bottom: $margin-medium;
        font-family: $bold;
        font-size: $fs-subheader;
    }

}

// DIRECTIONS

.directions {
    margin-bottom: $margin-section;

    &-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 20px;
    }
}

.direction {
    border-radius: $border-radius-cards;
    overflow: hidden;
    position: relative;
    height: 375px;

    &-link {
        @include full-sized-link;
        cursor: pointer;
    }

    &-name {
        position: absolute;
        bottom: 32px;
        left: 48px;
        color: $white;
        font-size: $fs-title;
        font-family: $bold;
        line-height: $fs-title-bigger;
        max-width: 265px;
        z-index: 30;
    }

    &-subcats {
        grid-template-columns: repeat(5, auto);
        padding-top: 16px;
    }

    &-subname {
        font-size: $fs-text;
        font-family: $medium;
        margin-right: $margin-cards;
        position: relative;
        padding-left: 12px;
        white-space: nowrap;
        color: rgba(255, 255, 255, .66);

        &::after {
            content: '⋅';
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &-img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: all .33s ease-in;
    }

    &::after {
        @include after-bg;
        @include gradient-linear(top, rgba(0, 0, 0, .2), rgba(0, 0, 0, .5));
        z-index: 20;
    }

    &:hover &-img {
        transform: scale(1.05);
    }

}

// TRAINERS

.trainers {
    margin-bottom: $margin-section;

    & .section-header {
        margin-bottom: 38px;
    }

    & .tns-controls {
        @include top-nav;
    }

    &-slider {
        padding-bottom: $margin-medium;
        padding-top: 10px;
    }

    &-slide {
        height: 500px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        box-shadow: $box-shadow-cards;
        transition: margin-top .25s ease;

        &::before {
            @include after-bg;
            width: calc(100% - 12px);
            border-radius: $border-radius-cards;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33000000', endColorstr='#80000000', GradientType=0);
        }

        &::after {
            @include after-bg;
            @include blur(30px);
            @include brightness(66%);
            width: calc(100% - 12px);
            opacity: 0;
            z-index: 10;
            transition: all .45s ease;
            border-radius: $border-radius-cards;
        }

        &:hover {
            margin-top: -10px;
        }

        &:hover::after {
            opacity: 1;
        }

        &-opener {
            visibility: hidden;
            opacity: 0;
            display: none;
        }

        &-photo {
            border-radius: $border-radius-cards;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-hidden {
            position: absolute;
            width: calc(100% - 12px);
            top: calc(100% - 128px);
            left: 0;
            padding: $padding-cards-medium;
            transition: all .25s ease;
            color: $white;
            z-index: 15;
        }

        &-name {
            color: $white;
            font-family: $bold;
            font-size: $fs-title;
            margin-bottom: $margin-small;
            max-width: 80%;
            overflow: hidden;
            line-height: $fs-title;
        }

        &-titles {
            font-size: $fs-text;
            margin-bottom: $margin-medium;
            visibility: hidden;
            transition: visibility .25s ease;
        }

        &-text {
            color: $white;
            font-family: $regular;
            font-size: $fs-text;
            line-height: $lh-text;
            opacity: .66;
        }

        &-direction {
            position: absolute;
            top: 15px;
            left: 13px;
            font-family: $medium;
            z-index: 10;
            color: $white;
            padding: 7px 21px;
            text-transform: uppercase;
            font-size: $fs-info;
            background-color: $accent-color;
            border-radius: 14px;
        }

        &-link {
            @include full-sized-link;
        }

        &:hover &-hidden {
            top: 0;
        }

        &:hover &-titles {
            visibility: visible;
        }
    }
}

// INSTAGRAM

.instagram {
    margin-bottom: $margin-section-big;

    &-slider {
        padding-bottom: $margin-medium;
        padding-top: 10px;
    }

    & .tns-controls {
        @include top-nav;
    }

    & .section-header {
        margin-bottom: 38px;
    }

    &-slide {
        overflow: hidden;
        height: 351px;
        position: relative;
        display: grid;
        border-radius: $border-radius-cards;
        cursor: pointer;
        transition: margin-top .25s ease;

        &:hover {
            margin-top: -10px;
        }

        &::before {
            @include after-bg;
            border-radius: $border-radius-cards;
            width: calc(100% - 12px);
            top: 100%;
            background: rgba(0, 0, 0, .75);
        }

        &:hover::before {
            top: 0;
        }

        &-link {
            @include full-sized-link;
        }

        &-hidden {
            transition: top .25s ease;
            color: $white;
            font-size: $fs-text;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding: $padding-cards-medium;
        }

        &:hover .instagram-slide-hidden {
            top: 0;
        }

        &-photo {
            border-radius: $border-radius-cards;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-name {
            margin-bottom: $margin-medium;
            font-family: $bold;
        }

        &-text {
            color: $white;
        }
    }
}


.insta-btn {
    margin-left: $margin-medium;
    padding: 12px 48px 12px 64px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: 24px;
        background: url('/img/instagram.svg') no-repeat center/contain;
        filter: brightness(100);
        width: 20px;
        height: 20px;
        display: inline-block;
    }
}

// PRICES

.prices {
    margin-bottom: $margin-section-big;
}

.personal-trainings {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
    padding: $padding-big 72px;
    margin-bottom: $margin-section;
}

.personal-trainings-text,
.club-cards-text {
    max-width: 520px;
}

.personal-trainings-right {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
}

.personal-trainings-offer .articles-header,
.club-card-offer .articles-header {
    margin-bottom: 8px;
}

.personal-trainings-cost,
.club-cards-cost {
    font-family: $regular;
    font-size: $fs-subheader;
    margin-bottom: $margin-cards;
}

.other-prices {
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 42px;
}

.other-prices-grid {
    grid-template-columns: 1fr auto;
}

.tabs {
    padding: 24px 0 0;
}

.tabs input[type="radio"] {
    display: none;
}

.tabs input[type="radio"] + label {
    text-align: center;
    padding: 14px 24px;
    border-radius: 24px;
    display: inline-block;
    color: $main-text-color;
    cursor: pointer;
    background-color: $light-background;
    transition: background-color .25s ease-in;
    margin-right: 8px;
}

.tabs input[type="radio"]:checked + label {
    background-color: $accent-color;
    color: $white;
}

.tabs-div {
    display: none;
}

#tab-btn-1:checked ~ #content-1,
#tab-btn-2:checked ~ #content-2, {
    display: grid;
}

.club-cards {
    padding-bottom: 24px;
}

.club-cards-offers {
    padding-top: 12px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
}

.club-card-offer {
    padding: 24px 0;
}

.club-cards-img {
    margin-bottom: $margin-medium;
    transition: all .25s ease-in;
    border-radius: $border-radius-cards;
    max-width: 280px;
    width: 100%;
}

.club-card-offer:hover .club-cards-img {
    margin-top: -10px;
    margin-bottom: $margin-medium+10px;
}

.trial-trainig-text {
    margin-bottom: 32px;
}

// MAP

.map-contacts {
    position: absolute;
    top: 180px;
    right: 0;
}

.scheme {
    margin: 80px 0 128px;

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 48px;
    }

    &__item {
        display: grid;
        grid-template-columns: 64px auto;
        grid-column-gap: 12px;
    }

    &__image {
        max-width: 64px;
        margin-right: 24px;
    }

    &__text {
        max-width: 480px;
        font-family: $regular;
        font-size: $fs-text;
        line-height: $fs-text*1.5;
    }

}

.form {
    &-title {
        font-size: $fs-title-bigger;
        font-family: $bold;
        margin-bottom: $margin-cards;
    }

    &-subtitle {
        font-size: $fs-text;
        font-family: $bold;
        margin-bottom: $margin-small;
    }

    &-contacts-phone {
        display: inline-block;
        font-size: $fs-text;
        font-family: $regular;
        margin-bottom: $margin-medium;
        position: relative;
        color: $black;
        @include underlined;
    }

    &-contacts-time {
        margin-bottom: 6px;
    }

    &-contats-text {
        margin-bottom: $margin-medium;
    }
}

#map {
    background: $light-background;
    height: 640px;
}

.pnlm-hotspot {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fff;
}

.pnlm-hotspot:after {
    content: '';
    position: absolute;
    background: url("/img/close.svg") no-repeat;
    background-position: center;
    background-size: cover;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.pnlm-hotspot:hover {
    cursor: pointer;
}

.pnlm-hotspot:hover:after {
    width: 11px;
    height: 11px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: 0.3s;
}

.pnlm-hotspot span {
    background-color: #fff;
    border-radius: none;
    padding: 10px 20px;
    width: 230px;
}

.pnlm-sprite {
    background-color: #fff;
    transition: 0.3s all ease-in;
}

.pnlm-sprite:hover {
    background-color: $accent-color;
}

.pnlm-tooltip:hover span:after {
    border-color: white transparent transparent transparent;
}

.custom-hotspot {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fff;
    transition: background-color .25s ease-in;
}

.custom-hotspot:after {
    content: '';
    color: #1d1d1d;
    background: url("/img/up-arrow.svg") center center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.custom-hotspot:hover {
    background-color: $accent-color;
    cursor: pointer;
}

.custom-hotspot span {
    background-color: #fff;
    border-radius: none;
    padding: 10px 18px;
    font-weight: 400;
    font-size: 15px;
    color: #303030;
    line-height: 24px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.pnlm-panorama-info {
    display: none;
    opacity: 0;
    background-color: #fff;
    padding: 5px 10px;
    bottom: 0;
}

.pnlm-title-box {
    display: none;
    font-weight: 400;
    font-size: 25px;
    color: #1d1d1d;
    padding: 0;
    margin: 0;
}

.pnlm-info span {
    color: #1e1e1e;
}

.panorama_caption {
    font-size: 20px;
    color: #1d1d1d;
    text-align: left;
    margin-bottom: 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.panorama_description {
    font-size: 16px;
    color: #1d1d1d;
    line-height: 1.2em;
    text-align: left;
    overflow: hidden;
    max-height: 58px;
}


@media screen and (max-width: 1600px) {
    .container {
        padding: 0 15px;
    }
    .banner-slide-block {
        margin-left: 128px;
    }

    .about {
        padding-bottom: 48px;
    }
    .tour-form {
        right: 16px;
    }
    .map-contacts {
        right: 64px;
    }

}

@media screen and (max-width: 1366px) {

    .container {
        max-width: 1330px;
        padding: 0 15px;
    }

    .btn {
        font-size: $fs-label;
        padding: 16px 32px;
    }

    .insta-btn {
        padding-left: 64px;
    }

    .directions-grid {
        grid-column-gap: 20px;
    }

    .tour-form {
        right: 32px;
        max-width: 320px;
        padding: $padding-cards-medium;
    }

    .trainers .tns-controls,
    .instagram .tns-controls,
    .about .tns-controls {
        right: 15px;
        width: auto;
    }

    .personal-trainings {
        grid-column-gap: 48px;
        padding: $padding-cards-medium;
        margin-bottom: $margin-cards;
    }

    .other-prices {
        grid-column-gap: 24px;
    }
    .club-cards,
    .trial-training {
        padding: $padding-cards-medium;
    }

    .articles-header {
        font-size: $fs-text;
    }

    .map-contacts {
        right: 64px;
        padding: $padding-cards-medium;
        max-width: 320px;
    }


}

@media screen and (max-width: 1200px) {
    body {
        padding-left: 64px;
    }
    .container {
        max-width: 1170px;
        padding: 0 15px;
    }
    .banner-slide-block {
        margin-left: 64px;
    }
    .tour-form {
        bottom: 80px;
    }
    .direction-subcats {
        @include grid-reset;
    }
}

@media screen and (max-width: 992px) {
    body {
        padding-left: 0;
    }
    .main {
        padding-top: 50px;
    }
    .container {
        max-width: 960px;
    }
    .form {
        &-paragraph {
            font-size: $fs-info;
            margin-bottom: $margin-small;
        }

        &-wrap {
            margin-bottom: 6px;
        }

        &-subtext {
            font-size: 12px;
            margin-bottom: $margin-small;
        }

        &-input {
            height: 48px;
            padding: 10px 24px;
        }
    }
    .section-header {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .tns-outer .tns-nav {
        position: relative;
        width: 100%;
        right: auto;
        top: auto;
        bottom: 64px;
        flex-direction: row;
        height: 24px;
    }
    .tns-outer .tns-nav [aria-controls] {
        height: 8px;
        width: 8px;
        padding: 0;
        margin-right: 25px;
    }
    .tns-outer [aria-controls].tns-nav-active {
        margin-right: 20px;
        width: 12px;
        height: 12px;
    }

    .banner {
        height: calc(100vh - 50px);
        max-height: 610px;

        &-slide {

            height: calc(100vh - 50px);
            max-height: 610px;

            &-block {
                margin: 72px auto 0;
                padding: 0;
                text-align: center;
            }

            &-header {
                margin-bottom: 33vh;
                font-size: 32px;
                line-height: 32px;
            }

            &-text {
                color: $white;
                font-size: $fs-info;
                line-height: $fs-info*1.3;
                margin-bottom: $margin-cards;
                padding: 0 32px;
            }
        }
    }

    .media {
        margin-bottom: $margin-section;

        &-grid {
            grid-column-gap: 32px;
        }

        &-card {
            margin-top: 0;

            &-name {
                font-size: 20px;
                margin-bottom: 16px;
            }

            &-paragraph {
                font-size: 15px;
                line-height: 20px;
            }
        }

        &-paragraph {
            font-size: $fs-info;
            line-height: $lh-text;
        }
    }

    section.address-mobile {
        display: block;
        margin-bottom: 42px;

        & .header-address {
            display: block;
            color: $main-text-color;
            padding: 0 24px 0 72px;

            &::after {
                top: 6px;
                left: 24px;
            }
        }
    }

    .about {
        background-color: $white;
        padding-top: 0;
        margin-bottom: 0;
    }

    .tour {
        &-form {
            position: relative;
            right: auto;
            bottom: auto;
            max-width: none;
        }

        &-navigation-link {
            display: inline-block;
            margin-bottom: 6px;
        }
    }

    .direction {
        height: 300px;
    }


    .instagram-slide {
        height: 300px;
    }

    .personal-trainings {
        @include grid-reset;
        margin-bottom: 24px;
        grid-row-gap: 24px;
    }

    .other-prices {
        @include grid-reset;
        grid-row-gap: 24px;
    }


    .map-contacts {
        position: relative;
        top: auto;
        right: auto;
        width: auto;
        max-width: none;
        margin-bottom: $margin-medium;
    }

    .scheme {
        &__wrapper {
            display: grid;
            grid-template-columns: auto;
            grid-row-gap: 32px;
        }

        &__text {
            max-width: none;
        }
    }

}

@media screen and (max-width: 768px) {
    .container {
        max-width: 720px;
    }

    .tns-outer {
        position: relative;
    }

    .tns-outer .tns-nav {
        margin-top: 24px;
        padding-bottom: 8px;
    }
    .tns-outer .tns-nav [aria-controls] {
        height: 6px;
        width: 6px;
        padding: 0;
        margin: 0 6px 0 0;
    }
    .tns-outer [aria-controls].tns-nav-active {
        margin: 0 6px 0 0;
        width: 6px;
        height: 6px;
    }

    .tns-controls {
        position: absolute;
        z-index: 80;
        top: calc(50% - 52px);
        left: 0;
        width: 100%;
        text-align: left;
        padding: 0 12px;
        height: 0;
        display: flex;
        justify-content: space-between;
    }


    .media {
        margin-bottom: $margin-medium;

        &-grid {
            grid-template-columns: auto;
            grid-template-rows: auto 1fr;
            grid-row-gap: 24px;
            margin-bottom: $margin-small;
        }

        &-text {
            grid-row: 2;
            padding-top: 32px;
        }

        &-card {
            grid-row: 1;

            &-text {
                padding: 24px 32px 32px;
            }
        }

        &-card-clip {
            height: 250px;
        }

        &-carousel {
            &-item {
                overflow: visible;
                box-shadow: $box-shadow-cards;

                &:hover {
                    margin: 0;
                }

                &:hover::before {
                    display: none;
                }

                &:hover .media-carousel-label {
                    bottom: auto;
                }
            }

            &-img {
                border-radius: 8px 8px 0 0;
            }

            &-label {
                position: relative;
                bottom: auto;
                left: auto;
                width: 100%;
                padding: 24px 32px;
                color: $main-text-color;
                font-size: $fs-info;
            }
        }
    }
    .about {
        padding-bottom: 0;

        &-slider {
            margin-bottom: $margin-medium;
        }

        &-slide {
            &-img {
                height: 300px;
                object-fit: cover;
            }

            &-name {
                font-size: $fs-text;
            }

            &-text {
                font-size: $fs-label;
            }

            &-info {
                padding: 24px 32px;
            }
        }

        & .tns-controls {
            top: calc(50% - 128px);
        }

        & .tns-outer .tns-nav {
            margin-top: 8px;
        }
    }
    .directions-grid {
        @include grid-reset;
    }
    .direction {
        &-name {
            font-size: 28px;
            left: 32px;
            bottom: 24px;
            line-height: 28px;
        }
    }
    .tour {
        height: 480px;

        &-navigation {
            overflow-x: scroll;
            display: grid;
            grid-column-gap: 8px;
            grid-template-columns: repeat(5, 1fr);
            padding-bottom: 6px;
            margin-bottom: 32px;

            &-link {
                white-space: nowrap;
            }
        }

        &-form {
            background: none;
            padding: 0;
            margin: 0;
            box-shadow: none;
        }

        &-subtitle {
            display: none;
        }
    }

    .trainers {
        margin-bottom: 24px;

        & .section-header {
            margin-bottom: 12px;
        }

        & .tns-controls {
            top: calc(50% - 64px);
            width: 100%;
        }

        &-slide {
            &:hover {
                margin-top: 0;
            }

            &-name {
                font-size: 32px;
            }

            &.active &-name {
                font-size: 24px;
                max-width: 90%;
            }

            &-hidden {
                top: calc(100% - 136px);
            }

            &-opener {
                background: $dark-background;
                color: $white;
                font-size: 20px;
                font-family: $bold;
                text-align: center;
                position: absolute;
                top: 16px;
                right: 16px;
                width: 48px;
                height: 48px;
                z-index: 1000;
                visibility: visible;
                display: block;
                padding-top: 12px;
                opacity: 1;
                border-radius: 50%;
                transition: background-color .25s ease-in;
            }

            &-opener.opened {
                background: url(/img/close.svg) center/contain no-repeat $light-background;
                background-size: 16px;
                text-indent: -9999px;
                overflow: hidden;
            }

            &:hover &-hidden {
                top: calc(100% - 136px);
            }

            &:hover::after {
                visibility: hidden;
                opacity: 0;
            }

            &:hover &-titles {
                visibility: hidden;
                opacity: 0;
            }

            &-hidden {
                padding: 32px;
            }

            &.active:hover::after,
            &.active &-titles {
                visibility: visible;
                opacity: 1;
            }

            &.active &-hidden {
                top: 0;
            }


        }

        & .tns-outer .tns-nav {
            margin-top: 48px;
        }
    }

    .tour-navigation-link {
        margin-right: 0;
    }

    .insta-btn {
        margin: 24px 0 0;
    }

    .instagram {
        margin-bottom: 48px;

        &-slide {
            height: auto;

            &:hover {
                margin-top: 0;
            }

            &::before {
                display: none;
            }

            &-photo {
                height: 170px;
            }

            &-hidden {
                display: none;
            }
        }

        & .tns-controls {
            display: none;
        }
    }

    .section-header {
        font-size: 20px;
        text-align: left;
    }

    .personal-trainings {
        padding: 0;
        box-shadow: none;

        &-right {
            @include grid-reset;
            grid-row-gap: 24px;
        }

        &-offer.first-type,
        &-offer.second-type {
            background-color: #E23030;
            color: $white;
            padding: 24px;
            border-radius: 8px;
        }

        .personal-trainings-offer .btn {
            background: #fff;
            border-radius: 6px;
            color: $main-text-color;
        }

        &-offer.second-type {
            background-color: #C52929;
        }

        .personal-trainings-cost {
            color: #FFF;
        }

    }

    .club-cards {
        padding: 24px 0;
        box-shadow: none;
    }

    .club-card-offer:hover .club-cards-img {
        margin: 0 0 8px;
    }

    .other-prices-grid {
        @include grid-reset;
    }

    .club-cards-offers {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
    }

    .club-cards-img {
        margin-bottom: 8px;
    }

    .club-cards-offers .articles-header {
        font-size: $fs-label;
    }

    .trial-training {
        padding: 0;
        box-shadow: none;
    }

    .prices {
        margin-bottom: 48px;
    }

    .prices .btn {
        padding: 12px 18px;
        font-size: 13px;
        border-radius: 6px;
    }

    .club-cards-cost, .personal-trainings-cost {
        font-size: 18px;
    }

    .map-contacts .form-title {
        display: none;
    }
    .map-contacts {
        box-shadow: none;
        padding: 0;
        margin: 0 0 32px;
    }

    #map {
        height: 480px;
    }

}

@media screen and (max-width: 575px) {
    .container {
        max-width: 540px;
    }
    .banner-slide-img[data-number='1'] {
        object-position: -550px 0;
    }
    .form-paragraph {
        font-size: 12px;
    }
    .trainers {
        &-slide {
            height: 460px;
        }

        &-slide::before,
        &-slide::after {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {


    section.address-mobile {

        & .header-address {
            padding: 0 0 0 48px;

            &::after {
                top: 6px;
                left: 0;
            }
        }
    }

    .form-paragraph {
        text-align: left;
    }
    .form-input {
        font-size: 14px;
    }
    .about {
        & .tns-controls {
            width: 100%;
        }

        &-slide-info {
            padding: 24px 24px 32px;
        }

        .tns-outer .tns-nav {
            margin-top: 16px;
        }

        &-slide-text {
            line-height: 18px;
        }
    }

    .media {
        margin-bottom: 0;
    }

    .direction-name {
        left: 24px;
    }

    .instagram {
        margin-bottom: 0;

        .tns-nav {
            margin-top: 48px;
        }
    }
    .container {
        max-width: none;
    }
    .scheme__item {
        grid-template-columns: 32px auto;
    }
    .scheme__image {
        margin-right: 4px;
        width: 32px;
    }
    .scheme__text {
        font-size: 15px;
        line-height: 20px;
    }

    .tns-outer [aria-controls],
    .tns-outer [data-action] {
        height: 32px;
        width: 32px;
        background-size: 6px !important;
    }

    .tns-controls {
        top: calc(50% - 32px);
    }

}

@media screen and (max-width: 320px) {

    .trainers {
        &-slide {
            &.active &-name {
                font-size: 20px;
                line-height: 22px;
                max-width: 90%;
            }

            &-titles {
                margin-bottom: 18px;
            }

            &-text {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

}
