@import "../mixins/mixins";

@import "../utils/variables";

.aside{
    position: fixed;
    width: 80px;
    z-index: 999;
    background-color: $white;
    top: 0;
    left: 0;
    height: 100vh;
    padding: 24px 0;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    box-shadow: 1px 0 2px rgba(0,0,0,.05);
    &-menu {
        &-content {
            position: absolute;
            top: 0;
            left: -400px;
            width: 400px;
            height: 100vh;
            padding: 96px 0 64px;
            overflow-y: -moz-scrollbars-vertical;
            visibility: hidden;
            background: $light-background;
            transition: left .25s ease, visibility .25s ease-in;
            z-index: 990;
            &.active {
                left: 80px;
                visibility: visible;
            }
            &.smaller-screen{
                padding-top: 48px;
                grid-row-gap: 24px;
            }
        }
        &-social {
            opacity: 0;
            visibility: hidden;
            display: grid;
            margin: 32px 0 0;
            width: fit-content;
            grid-template-columns: repeat(3, auto);
            grid-column-gap: 32px;
            align-items: center;
            & > a {
                margin: 0;
            }
        }
        &-anchor {
            font-size: $fs-text;
            color: $main-text-color;
            font-family: $medium;
            display: block;
            padding: 16px 48px;
            transition: background-color .33s ease;
            &:hover{
                background: $gray-background;
            }
        }
        &-contacts {
            padding-left: 48px;
        }
        &-p {
            font-size: $fs-info;
            color: $second-text-color;
            margin-bottom: $margin-small;
        }
        &-phone {
            display: block;
            color: $accent-color;
            font-size:$fs-text;
            position: relative;
            font-family: $bold;
        }
        &-cta{
            display: inline-block;
            font-size: $fs-label;
            margin-top: $margin-cards;
        }
    }

    &-social {
        width: 24px;
        align-self: end;
    }
    &-menu-btn,
    &-social-link {
        display: inline-block;
        text-indent: -9999px;
        width: 24px;
        height: 24px;
        background: url(/img/menu.svg) no-repeat center/contain;
        cursor: pointer;
        margin-bottom: $margin-cards;
        transition: all .3s ease-in;
    }
    &-menu-btn.active {
        background: url(/img/close.svg) no-repeat center/contain;
    }
    &-social-link.vk{
        background: url(/img/vk.svg) no-repeat center/contain;
    }
    &-social-link.inst{
        background: url(/img/instagram.svg) no-repeat center/contain;
    }
    &-social-link.fb{
        background: url(/img/facebook.svg) no-repeat center/contain;
    }
}

.faded-content {
    position: static;
    overflow: hidden;
    padding-right: 17px;
    &::before {
        @include after-bg;
        position: fixed;
        background: rgba(0, 0, 0, .66);
        z-index: 997;
    }
}

@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1200px) {
    .aside {
        width: 64px;
        &-menu-content {
            height: 100vh;
            overflow-y: scroll;

            &.active {
                left: 64px;
                grid-row-gap: 48px;
                padding-top: 48px;
            }
        }
        &-menu-contacts{
            padding-bottom: 64px;
        }
    }
}
@media screen and (max-width: 992px) {
    .aside {
        left: -80px;
        &-menu-content.active {
            padding-top: 24px;
            grid-row-gap: 24px;
        }
        &-menu-ahchor {
            padding: 14px 48px;
        }
        &-menu-btn {
            position: absolute;
            top: 32px;
            left: calc(100vw + 24px);
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            &.active{
                opacity: 1;
                visibility: visible;
            }
        }
        &-menu-social {
            visibility: visible;
            opacity: 1;
        }
    }
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 575px) {

}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 320px) {
    .aside-menu-anchor {
        padding: 14px 48px;
    }
}
