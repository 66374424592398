@import "../mixins/mixins";

@import "../utils/variables";


.overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 20;
    transition: .3s all;
}

.modal {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    max-width: 540px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 3px 10px -.5px rgba(0, 0, 0, .2);
    text-align: center;
    padding: $padding-cards-full;
    border-radius: 3px;
    background-color: #fff;
    transition: 0.3s all;
    z-index: 999;

    &-instagram {
        max-width: 800px;
        padding: 0;
        max-height: 480px;
    }

    &-content-grid {
        grid-template-columns: 480px auto;
        grid-column-gap: 24px;
        justify-items: left;
    }

    &-image {
        border-radius: 3px 0 0 3px;
        width: 480px;
        height: 480px;
        object-fit: cover;
    }

    &-text {
        text-align: left;
        line-height: 18px;
        font-family: $regular;
        padding: 48px 24px 32px 0;
        overflow-y: auto;
        max-height: 440px;
    }

    &-title {
        font-family: $bold;
        font-size: $fs-header;
        margin-bottom: $margin-cards;
    }

    &-service {
        max-width: 670px;
        padding-top: 24px;
        max-height: 100vh;
        overflow-y: -moz-scrollbars-vertical;
        overflow-y: auto;

        & .service-paragraph {
            text-align: left;
            padding: 24px 0;
            line-height: 1.2rem;
        }

        & .service-modal__p {
            margin-bottom: 8px;
        }

    }
}

.service-gallery {
    &__image {
        padding-bottom: 16px;
    }
}

.modal.active,
.overlay.active {
    opacity: 1;
    visibility: visible;
}

.modal__cross {
    display: inline-block;
    text-indent: -9999px;
    width: 24px;
    height: 24px;
    background: url(/img/close.svg) no-repeat center/contain;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    transition: all .25s ease-in;

    &:hover {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);;
        -moz-transform: scale(1.2);;
    }
}

.modal-instagram .modal__cross {
    top: 18px;
    right: 18px;
}

@media screen and (max-width: 1366px) {

}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {
    .modal {
        padding: $padding-cards-small;

        &-title {
            font-size: 22px;
        }

        &__cross {
            top: 18px;
            right: 18px;
            width: 18px;
            height: 18px;
        }

        &-service {
            padding: 16px 18px 24px;

            & .tns-nav {
                bottom: 0;
                margin: 0;
                padding: 0;
            }

            & .btn {
                width: 100%;
            }

        }

        & .service-paragraph {
            font-size: $fs-info;
            line-height: $fs-info*1.2;
            padding-top: 12px;
        }


        &-content-grid {
            grid-template-columns: auto;
        }

        &-instagram {
            max-width: 800px;
            padding: 0;
            max-height: none;

            & .modal__cross {
                z-index: 5;
                background: none;

                &::after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    background: url(/img/close.svg) no-repeat center/contain rgba(255, 255, 255, .5);
                    background-size: 12px;
                    top: 0;
                    right: 0;
                }
            }

        }

        &.modal-instagram {
            padding: 0;
        }

        &-image {
            border-radius: 0;
            width: 100%;
            height: 360px;
            object-fit: cover;
        }

        &-text {
            text-align: left;
            line-height: 18px;
            font-family: $regular;
            padding: 24px;
            max-height: none;
            overflow: auto;
        }


    }

}

@media screen and (max-width: 575px) {

    .modal {
        box-shadow: none;
        top: 0;
        left: 0;
        border-radius: 0;
        transform: none;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        & .service-paragraph {
            padding: 16px 0;
        }

        &-service .service-modal__p {
            font-size: 14px;
            line-height: 18px;
        }
    }
    .service-gallery__image {
        padding-bottom: 0;
    }
    .modal-title {
        text-align: left;
        font-size: 18px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 480px) {

}

@media screen and (max-width: 320px) {

}
