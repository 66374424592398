@import "../mixins/mixins";

@import "../utils/variables";

$footer-height: 100px;

.footer {
    background: $dark-background;
    height: $footer-height;
    color: $white;
    font-size: $fs-info;
    line-height: $lh-text;
    font-family: $regular;

    &-grid {
        grid-template-columns: 4fr 1fr;
        height: $footer-height;
        align-items: center;
    }

    &-left-col {
        grid-template-columns: 166px 205px 216px 102px;
        grid-column-gap: 72px;
        align-items: center;
    }

    &-logo {
        @include logo;
    }
}


.copyrights {
    color: $white;
    width: 205px;
}

.policy-link {
    color: $white;
    position: relative;
    display: inline-block;
    width: max-content;
    @include underlined;
}

.creators-link {
    color: $white;
    display: inline-block;
    position: relative;
    padding-left: 52px;

    &-text {
        color: $gray;
        display: block;
    }

    &-name {
        color: $white;
        transition: color 0.25s ease;
    }

    &:hover {
        .creators-link-name {
            color: #23C282;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 42px;
        height: 42px;
        background: url('/img/logo-creators.svg') no-repeat center/contain;
    }
}

@media screen and (max-width: 1366px) {
    .footer {
        &-left-col {
            grid-column-gap: 32px;
        }
        &-logo {
            margin-left: 0;
        }
    }
}
@media screen and (max-width: 1200px) {
    .footer-grid {
        grid-template-columns: 3fr 1fr;
    }
    .footer-logo {
        width: 64px;
        background-size: auto;
        background-position: left;
    }
    .footer-left-col {
        grid-template-columns: 96px auto auto auto;
        grid-column-gap: 0;
    }
    .policy-link,
    .creators-link,
    .copyrights {
        max-width: 180px;
        font-size: 12px;
        text-align: center;
        margin-bottom: $margin-small;
    }
}
@media screen and (max-width: 992px) {
    .footer {
        height: auto;
        padding: 48px 0;
        &-grid,
        &-left-col {
            @include grid-reset;
            height: auto;
            grid-row-gap: 12px;
            grid-column-gap: initial;
            justify-content: center;
            justify-items: center;
        }
    }
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 575px) {

}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 320px) {

}

